/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableHeading,
  TableCell,
  Button,
  Spinner,
  Tooltip,
  Text,
} from '@nike/eds';
import { format } from 'date-fns';
import { groupBy, groupWith } from 'ramda';
import { capitalize } from '@vl/js-lib/browser/string';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { usePatchContentMutation } from 'features/adminApi/endpoints/content';
import {
  useFetchCompiledConsentsQuery,
  usePatchConsentMutation,
} from 'features/adminApi/endpoints/consent';
import { usePatchScreenerMutation } from 'features/adminApi/endpoints/screener';
import { usePatchSurveyMutation } from 'features/adminApi/endpoints/survey';
import {
  useCompileConsentMutation,
  useCompileInquiryMutation,
  useCompileLayoutMutation,
} from 'features/adminApi/endpoints/cms';
import { useToasts } from 'react-toast-notifications';
import { ContentStatus, StatusMessage } from 'lib/enums';
import {
  CONSENT_FORM,
  SCREENER_FORM,
  SURVEY_FORM,
  formatAssetData,
  updateLayout,
  constructLayoutHandle,
  retrieveShortHandle,
} from 'lib/layoutUtils.js';
import styles from '../../styles.module.scss';
import { isEmpty } from 'lodash';
import { getEntitySubtype } from 'lib/auth.js';
import { userInitiativePermissions } from 'components/RBAC';
import { sortUnpublishedConsent } from '../../utils';
import {
  confirmModalDetails,
  confirmPublishModalContent,
  filterFields,
  headers,
  invalidDate,
  message,
  notAvailable,
} from './utils';
import { PublishModalStyled, TooltipMessage } from './styled';
import { participationStatusArray } from 'lib/util';
import { INQUIRIES } from 'components/forms/Assets/utils';
import { ConfirmPublishModalFooter } from '../Preview/ConfirmPublishModalFooter';
import { arrayOfObjects } from 'lib/propTypes';

// eslint-disable-next-line complexity
const CardContent = ({
  handle: initiativeHandle,
  versions: originalVersions,
  type,
}) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { addToast } = useToasts();
  const [draftExist, setDraftExist] = useState(false);
  const [isJustCompiled, setIsJustCompiled] = useState(false);
  const [visible, setVisible] = useState(false);
  const isConsentForm = type === CONSENT_FORM;
  const { id } = useParams();
  const {
    data: compiledConsents,
    isLoading,
    isError,
    error,
    refetch,
  } = useFetchCompiledConsentsQuery(initiativeHandle, {
    skip: type !== CONSENT_FORM,
    refetchOnMountOrArgChange: true,
    refetchInterval: 60000, // if user stays on same page without doing anything for 1 minutes then it will refetch
  });
  let publishedConsentVersion = 1;
  let isPublishedConsentIdExist = false;
  const isCompiling = isJustCompiled && draftExist;
  const shortHandle = retrieveShortHandle(initiativeHandle);

  // Re-fetch compiled-consent whenever component gets mounted.
  // redux query basically caches the data for API this helps to
  // fetch latest consent all the time when component mounts
  useEffect(() => {
    if (isConsentForm) {
      refetch();
    }
  }, []);

  useEffect(() => {
    if (isConsentForm && compiledConsents?.length) {
      const compileConsentSessionData = localStorage.getItem(
        'compiled-consent-data'
      );
      const sessionData = JSON.parse(compileConsentSessionData);
      if (
        sessionData &&
        sessionData?.isJustCompiled &&
        sessionData?.initiativeHandle === initiativeHandle &&
        sessionData?.compiledConsentLen === compiledConsents?.length
      ) {
        setIsJustCompiled(true);
      } else {
        setIsJustCompiled(false);
        localStorage.removeItem('compiled-consent-data');
      }
    }
  }, [isConsentForm, compiledConsents, isJustCompiled, initiativeHandle]);

  const dataByAssetType =
    originalVersions &&
    originalVersions.map((item) => ({
      contentType: item.contentType,
      record: item,
      status: item.status,
    }));

  const groupByStatus =
    dataByAssetType &&
    groupWith((first, last) => first.status !== last.status, dataByAssetType);

  const siblingContent = groupByStatus?.length >= 1 && dataByAssetType;

  const groupedContent =
    dataByAssetType &&
    groupBy((layout) => {
      return layout.status;
    }, dataByAssetType);

  const publishedContent = groupedContent?.published
    ? {
      ...groupedContent?.published
        .filter((itm) =>
          (itm?.record?.initiativeParticipationStatus
            ? itm?.record?.initiativeParticipationStatus === 'OPEN' ||
                itm?.record?.initiativeParticipationStatus === null
            : itm)
        )
        .pop()?.record,
    }
    : null;

  const previewContent = groupedContent?.preview
    ? {
      ...groupedContent?.preview
        .filter((itm) =>
          (itm?.record?.initiativeParticipationStatus
            ? itm?.record?.initiativeParticipationStatus === 'OPEN' ||
                itm?.record?.initiativeParticipationStatus === null
            : itm)
        )
        .pop()?.record,
    }
    : null;

  const updateFilteredContent = [
    { ...previewContent },
    { ...publishedContent },
  ];

  // To support unpublished consents
  // currently useFetchConsentQuery is not returning unpublished versions, so,changing
  // the below condition if (isConsentForm && groupedContent?.unpublished) {
  if (isConsentForm) {
    const sortedData = groupedContent?.unpublished?.sort(
      sortUnpublishedConsent
    );

    const sortedCompiledConsent = Array.isArray(compiledConsents)
      ? compiledConsents
        .filter(
          (item) =>
            item && item.version !== undefined && item.version !== null
        )
        .sort((item1, item2) => item1.version - item2.version)
      : [];

    const unpublishedConsents = sortedCompiledConsent
      ?.map((item) => ({
        id: item.id,
        handle: item.handle,
        initiativeHandle: item.initiativeHandle,
        contentType: CONSENT_FORM,
        record: {
          ...sortedData?.record,
          id: item.id,
          initiativeHandle: item.initiativeHandle,
          status: ContentStatus.UNPUBLISHED,
          version: item.version,
        },
      }))
      ?.slice(0, isJustCompiled ? undefined : -1)
      ?.reverse();

    unpublishedConsents.filter(
      (itm) =>
        itm?.contentType === CONSENT_FORM &&
        updateFilteredContent.push(itm.record)
    );

    // Depending on length of compile-consent to show version for published won't work in case of old INIT
    // As the records are less than the actual number of times consent has compiled
    publishedConsentVersion =
      (unpublishedConsents[0]?.record?.version || 0) + 1;
  }

  const filteredContent = updateFilteredContent.filter((item) => item.id);

  // Before V2
  // Once BE changes DONE for getting the initParticipationStatus then we can start using below
  // const publishedContentForTabs = groupedContent?.published
  //   ? groupedContent?.published.filter(
  //     (itm) => itm?.record?.initiativeParticipationStatus !== null
  //   )
  //   : [];
  // const previewContentForTabs = groupedContent?.preview
  //   ? groupedContent?.preview.filter(
  //     (itm) => itm?.record?.initiativeParticipationStatus !== null
  //   )
  //   : [];

  const publishedContentForTabs = groupedContent?.published || [];

  const previewContentForTabs = groupedContent?.preview || [];

  const allContentForTabs = [
    ...previewContentForTabs,
    ...publishedContentForTabs,
  ];

  // tabbedContent will contain an array of objects with truthy tabName values,
  // or an empty array if all tabName values are falsy
  const tabbedContent = allContentForTabs
    ? allContentForTabs.flatMap((layout) => {
      //Fetching the Initiative-participation-status based on handle of the details-card
      //TODO: Once BE ticket is done we should use the participation details from contents API.
      const tab = layout.record?.handle?.match(/[^-]+$/)?.[0]?.toUpperCase();
      const tabName = participationStatusArray.includes(tab) && tab;

      return tabName
        ? [
          {
            status: layout?.record?.status,
            tabName,
            layoutId: layout?.record?.id,
          },
        ]
        : [];
    })
    : null;

  const [publishing, setPublishing] = useState(false);
  const [usePatchConsent, patchConsentResult] = usePatchConsentMutation();
  const [usePatchContent, patchContentResult] = usePatchContentMutation();
  const [usePatchScreener, patchScreenerResult] = usePatchScreenerMutation();
  const [usePatchSurvey, patchSurveyResult] = usePatchSurveyMutation();

  //Redux hooks
  const [useCompileLayout] = useCompileLayoutMutation();
  const [useCompileInquiry] = useCompileInquiryMutation();
  const [useCompileConsent] = useCompileConsentMutation();

  const versions = filteredContent;

  const formatCell = (value, formatType = '') => {
    const formats = {
      capitalize: (text) => <b>{ capitalize(text) }</b>,
    };

    const isDate = value instanceof Date;
    if (isDate) return format(value, 'MMM dd, yyyy @ hh:mm zz');
    if (formatType) return formats[formatType](value);

    return value !== invalidDate ? value : notAvailable;
  };

  const filterData = useMemo(
    () =>
      versions &&
      versions.filter(
        (ver) => ver?.status?.toLowerCase() === ContentStatus.PREVIEW
      ),
    [versions]
  );

  useEffect(() => {
    const draftExists = !isEmpty(filterData);
    setDraftExist(draftExists);
  }, [filterData]);

  const filterTabbedContent = (layoutId) => {
    return tabbedContent
      ? tabbedContent.filter(
        (item) =>
          item?.status ===
            tabbedContent.filter((itm) => itm?.layoutId === +layoutId).pop()
              ?.status
      )
      : null;
  };

  const handleView = useCallback(
    (event) => {
      const layoutId = event.target?.value;
      const viewUrl = `${ url }/view/${ type }`;
      const version = event.target?.id;
      sessionStorage.setItem('layoutId', JSON.stringify(layoutId));
      history.push({
        pathname: viewUrl,
        state: {
          layoutId: layoutId && layoutId,
          siblingIds: filterTabbedContent(layoutId),
          disabled: true,
          draftExist,
          version,
        },
      });
    },
    [draftExist]
  );

  const handleEdit = useCallback(
    (event) => {
      const layoutId = event.target?.value;
      sessionStorage.setItem('layoutId', JSON.stringify(layoutId));
      const editurl = `${ url }/edit/${ type }`;
      const version = event.target?.id;
      history.push({
        pathname: editurl,
        state: {
          disabled: false,
          layoutId: layoutId && layoutId,
          siblingIds: filterTabbedContent(layoutId),
          draftExist,
          version,
        },
      });
    },
    [draftExist]
  );

  const confirmPublishModal = useCallback(() => {
    setVisible(!visible);
  }, [visible]);

  const handlePublish = useCallback(async () => {
    const [data] = filterData;

    const requestData =
      data &&
      (({ updateTimestamp, createTimestamp, categories, sections, ...rest }) =>
        rest)(data);

    if (requestData) {
      setPublishing(true);
      let handle = '';
      const formatData = formatAssetData(requestData, type);
      formatData.status = ContentStatus.PUBLISHED;

      if (isConsentForm) {
        await confirmPublishModal();
        await usePatchConsent(formatData);
      } else if (type === SCREENER_FORM) {
        await usePatchScreener(formatData);
        setPublishing(false);
      } else if (type === SURVEY_FORM) {
        await usePatchSurvey(formatData);
        setPublishing(false);
      } else {
        await usePatchContent(formatData);
        //Added to compile content here since we don't have the content handle
        //Other asset types compile in the postSuccess below
        //await useCompileLayout(layoutHandle);
        if (siblingContent) {
          const contentData = siblingContent
            .filter((item) => item?.record.status === 'preview')
            .map((layout) => {
              return layout.record;
            });
          contentData.map((layout) => {
            if (layout.id !== formatData.id) {
              //only should call siblings, not original
              updateLayout('patch', layout.id, usePatchContent, contentData);
            }
            //Compiles for all layouts with a participation status in layoutHandle
            const siblingData = contentData
              .filter((item) => item.id === layout.id)
              .pop();
            handle = siblingData?.handle;
            useCompileLayout(siblingData?.handle);
          });
        }
        setPublishing(false);
      }
      const buildLayoutHandle =
        type === SURVEY_FORM
          ? data.handle
          : constructLayoutHandle(shortHandle, type);
      if (buildLayoutHandle && handle !== buildLayoutHandle) {
        let isCompileSuccess;
        if (isConsentForm) {
          isCompileSuccess = await useCompileConsent({
            id: formatData?.id,
            initiativeHandle,
          });
        } else if (INQUIRIES.includes(type)) {
          await useCompileInquiry({
            id: formatData?.id,
            initiativeHandle,
            inquiryHandle: buildLayoutHandle,
          });
        }
        if (isCompileSuccess && isConsentForm) {
          setPublishing(false);
          setIsJustCompiled(true);
          localStorage.setItem(
            'compiled-consent-data',
            JSON.stringify({
              isJustCompiled: true,
              initiativeHandle,
              compiledConsentLen: compiledConsents?.length,
            })
          );
        }
      }
    }
  }, [filterData, publishing, setPublishing, siblingContent, compiledConsents]);

  useEffect(() => {
    if (patchContentResult.isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
    } else if (patchContentResult.isError) {
      addToast('Error while publishing content', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchContentResult.isSuccess]);

  useEffect(() => {
    if (patchConsentResult.isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
    } else if (patchConsentResult.isError) {
      addToast('Error while publishing consent form', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchConsentResult.isSuccess, patchConsentResult.isError]);

  useEffect(() => {
    if (patchScreenerResult.isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
      //We don't get any original arguments back, so we will need to compile upon POST/PATCH
    } else if (patchScreenerResult.isError) {
      addToast('Error while publishing screener', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchScreenerResult.isSuccess]);

  useEffect(() => {
    if (patchSurveyResult.isSuccess) {
      addToast('Success', { appearance: 'success', autoDismiss: true });
    } else if (patchSurveyResult.isError) {
      addToast('Error while publishing survey', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [patchSurveyResult.isSuccess]);

  useEffect(() => {
    if (isError && error) {
      addToast('Error while fetching compiled-consents', {
        appearance: 'error',
        autoDismiss: true,
      });
    }
  }, [isError]);

  const initPermissions = userInitiativePermissions(id, getEntitySubtype(type));

  const getVersionStatus = useCallback(
    (version) => version?.status?.toLowerCase(),
    []
  );

  const rows = versions?.reduce((acc, version) => {
    if (isConsentForm && version?.status === ContentStatus.PUBLISHED) {
      isPublishedConsentIdExist = compiledConsents?.find(
        (consent) => consent.layoutId === version.id
      );
    }

    const isPendingPublish = isConsentForm && !isPublishedConsentIdExist;

    const updateStatus =
      version?.status === ContentStatus.PREVIEW ? (
        StatusMessage.PREVIEW
      ) : version?.status === ContentStatus.UNPUBLISHED ? (
        StatusMessage.UNPUBLISHED
      ) : isPendingPublish ? (
        isLoading ? (
          <Spinner />
        ) : (
          StatusMessage.COMPILING
        )
      ) : (
        version.status
      );

    const dateFormatted = new Date(version.updateTimestamp).toLocaleString();

    const assetActionMenu = (type) => {
      const subType = getEntitySubtype(type);

      return [
        {
          action: 'edit',
          entity: subType,
          key: 'edit-id',
          label: 'edit',
          url: '#',
          component: (
            <Button
              variant="primary"
              size="small"
              onClick={ handleEdit }
              value={ version?.id }
              key="edit-id"
            >
              Edit
            </Button>
          ),
        },
        {
          action: 'read',
          entity: subType,
          key: 'view-id',
          label: 'view',
          url: '#',
          component: (
            <Button
              variant="primary"
              size="small"
              onClick={ handleView }
              id={
                version?.status === ContentStatus.UNPUBLISHED
                  ? version?.version
                  : 0
              }
              value={ version?.id }
              key="view-id"
            >
              View
            </Button>
          ),
        },
        {
          action: 'create',
          entity: subType,
          key: 'publish-id',
          label: 'publish',
          url: '#',
          component: (
            <div key="publish-id">
              { isCompiling ? (
                <Tooltip
                  bodySlot={ <TooltipMessage>{ message }</TooltipMessage> }
                  placement={ 'bottom' }
                  enableFocus={ true }
                  isDark={ false }
                >
                  <Button
                    onClick={
                      isConsentForm ? confirmPublishModal : handlePublish
                    }
                    variant="secondary"
                    size="small"
                    value={ version?.id }
                    disabled={ isCompiling }
                    style={{ cursor: 'not-allowed' }}
                  >
                    { publishing ? <Spinner size="medium" /> : 'Publish' }
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  onClick={ isConsentForm ? confirmPublishModal : handlePublish }
                  variant="secondary"
                  size="small"
                  value={ version?.id }
                >
                  { publishing ? <Spinner size="medium" /> : 'Publish' }
                </Button>
              ) }
            </div>
          ),
        },
      ];
    };

    const permissibleActions =
      initPermissions.length > 0
        ? assetActionMenu(type).filter((item) =>
          initPermissions.includes(item.action.toUpperCase())
        )
        : [];

    const showPublish = (version, ContentStatus) =>
      getVersionStatus(version) === ContentStatus.PREVIEW;

    const showEdit = (version, ContentStatus, draftExist) => {
      const statusLower = getVersionStatus(version);
      return (
        statusLower === ContentStatus.PREVIEW ||
        (statusLower === ContentStatus.PUBLISHED && !draftExist)
      );
    };

    const showView = (version, ContentStatus) => {
      const statusLower = getVersionStatus(version);
      return (
        statusLower === ContentStatus.PUBLISHED ||
        statusLower === ContentStatus.UNPUBLISHED
      );
    };

    const row = [
      {
        id: 'status',
        content: formatCell(updateStatus, 'capitalize'),
        alignment: 'left',
      },
      {
        id: 'versionNumber',
        content: isLoading ? (
          <Spinner />
        ) : version?.status === ContentStatus.PREVIEW ? (
          notAvailable
        ) : version?.status === ContentStatus.UNPUBLISHED ? (
          version?.version
        ) : !isPendingPublish ? (
          publishedConsentVersion
        ) : (
          notAvailable
        ),
        alignment: 'left',
      },
      {
        id: 'lastUpdated',
        content: formatCell(dateFormatted),
        alignment: 'left',
      },
      {
        id: 'actions',
        alignment: 'right',
        content: (
          <div className={ styles.actions }>
            { permissibleActions
              .filter(
                (action) =>
                  (showPublish(version, ContentStatus) &&
                    action.label === 'publish') ||
                  (showEdit(version, ContentStatus, draftExist) &&
                    action.label === 'edit') ||
                  (showView(version, ContentStatus) && action.label === 'view')
              )
              .map((action) => action.component) }
          </div>
        ),
      },
    ];
    return [...acc, row];
  }, []);

  return (
    <div>
      <Table className={ styles.table }>
        <thead>
          <tr>
            { headers.map(
              (column) =>
                filterFields(column.field, type) || (
                  <TableHeading key={ column.field }>
                    { column.headerName }
                  </TableHeading>
                )
            ) }
          </tr>
        </thead>
        <tbody>
          { rows?.map((row, i) => (
            <tr key={ i }>
              { row.map(
                (cell) =>
                  filterFields(cell.id, type) || (
                    <TableCell
                      key={ cell.id }
                      hasError={ cell.hasError }
                      alignment={ cell.alignment }
                    >
                      { cell.content }
                    </TableCell>
                  )
              ) }
            </tr>
          )) }
        </tbody>
      </Table>
      { isConsentForm && (
        <PublishModalStyled
          isOpen={ visible }
          onDismiss={ confirmPublishModal }
          headerSlot={
            <Text font="title-3" as="h3">
              { confirmModalDetails.heading }
            </Text>
          }
          footerSlot={
            <ConfirmPublishModalFooter
              handlePublish={ handlePublish }
              confirmPublishModal={ confirmPublishModal }
            />
          }
        >
          { confirmPublishModalContent(compiledConsents?.length) }
        </PublishModalStyled>
      ) }
    </div>
  );
};

CardContent.propTypes = {
  handle: PropTypes.string,
  type: PropTypes.string,
  versions: arrayOfObjects,
};

export default CardContent;
