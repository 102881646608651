/* eslint-disable max-statements */
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { AddEditForm } from '../components';
import { useHistory, useParams } from 'react-router-dom';
import {
  getFormFields,
  convertHandleToCardType,
} from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import {
  checkAndUpdateScreenerFields,
  formatInquiryDataForAsset,
  SURVEY_FORM,
} from 'lib/layoutUtils';
import {
  useFetchSurveyQuery,
  useCreateSurveyMutation,
  usePatchSurveyMutation,
  useFetchSurveyByIdQuery,
} from 'features/adminApi/endpoints/survey';
import { isEmpty } from 'lodash';
import { usePPIlist } from 'lib/hooks/usePPIlist.js';
import { useFetchConfigQuery } from 'features/adminApi/endpoints/config';
import { PPI_ANSWER_OPTIONS } from 'lib/configs';
import { filteredAsset } from '../utils';
import { ViewMode } from 'lib/enums';

const AddEditSurvey = ({ edit }) => {
  const { id, type, viewMode } = useParams();
  const history = useHistory();
  const [layoutId, setlayoutId] = useState();
  const [formFields, setFormFields] = useState([]);
  const [initialData, setInitialData] = useState({});

  const { data, isLoading } = useFetchSurveyQuery(id);
  const [usePatchSurvey, patchSurveyResult] = usePatchSurveyMutation();
  const [useCreateSurvey, postSurveyResult] = useCreateSurveyMutation();
  const { data: surveyData, isLoading: isSurveyLoading } =
    useFetchSurveyByIdQuery(
      { initiativeHandle: id, id: layoutId },
      {
        skip:
          !id ||
          !layoutId ||
          type !== SURVEY_FORM ||
          viewMode === ViewMode.CREATE,
      }
    );

  const ppiList = usePPIlist();
  const { data: { value: configData } = {}, isFetching } =
    useFetchConfigQuery(PPI_ANSWER_OPTIONS);

  if (!isFetching && configData) {
    window.localStorage.setItem(
      'ppi-answer-options',
      JSON.stringify(configData)
    );
  }

  useEffect(() => {
    const id = history?.location?.state?.layoutId
      ? history?.location?.state?.layoutId
      : JSON.parse(sessionStorage.getItem('layoutId')) || initialData.id;
    setlayoutId(parseInt(id, 10));
  }, [history, initialData]);

  useEffect(() => {
    const selectedData = surveyData
      ? surveyData.inquiryType === SURVEY_FORM && surveyData
      : layoutId && data
        ? data.filter((itm) => itm.id === layoutId).pop()
        : [];

    const transformedData =
      !isEmpty(selectedData) &&
      formatInquiryDataForAsset(selectedData, SURVEY_FORM);
    const formFields =
      getFormFields(transformedData, 'survey', ppiList).elements[0].value || [];

    const initialData = formFields;
    setFormFields(formFields);
    setInitialData(initialData);
  }, [data, surveyData, layoutId, ppiList, viewMode]);

  //TODO uncomment if add a preview
  //   useEffect(() => {
  //     const prev = { ...preview, questionSet: initialData.questionSet };
  //     if (!isEmpty(prev?.questionSet)) setPreview(prev);
  //   }, [initialData?.questionSet]);

  const layoutType = convertHandleToCardType(SURVEY_FORM);

  const finalData = useMemo(
    () => (viewMode !== ViewMode.CREATE ? filteredAsset(data, surveyData) : []),
    [data, surveyData]
  );

  return isLoading || isSurveyLoading ? (
    <Loading show={ true } text="Fetching Survey Details" />
  ) : (
    <AddEditForm
      layoutId={ +layoutId }
      submitText={ 'Save Survey' }
      patchResult={ patchSurveyResult }
      postResult={ postSurveyResult }
      useCreate={ useCreateSurvey }
      usePatch={ usePatchSurvey }
      viewMode={ viewMode }
      data={ finalData }
      edit={ edit }
      isLoading={ isLoading }
      id={ id }
      initialData={ initialData }
      assetType={ SURVEY_FORM }
      layoutType={ layoutType }
      formFields={ formFields }
      history={ history }
      updateFields={ checkAndUpdateScreenerFields }
      initiativeHandle={ id }
    />
  );
};

AddEditSurvey.propTypes = {
  edit: PropTypes.bool,
  viewMode: PropTypes.string,
};

export default AddEditSurvey;
