import React from 'react';
import PropTypes from 'prop-types';
import { SCREENER_FORM } from 'lib/layoutUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import {
  QuestionSetTypes,
  getFieldValueByFieldName,
  getQuestionSetByFieldName,
} from '../../RepeatableFieldsetUtils';
import { RadioSet } from 'components/fields';
import { getFieldLabel } from '../../lib';
import { Field } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { initialValuesTypes, selectOptionPropType } from 'lib/propTypes';

export const InquiryRadioField = ({
  disabled,
  fieldId,
  fieldName,
  fieldLabel,
  fieldValue,
  hasErrors,
  errorMessage,
  placeholder,
  onChange,
  layoutType,
  fieldOptions,
  formState,
  questionSet,
}) => {
  return (
    <FieldWrapper disabled={ disabled }>
      <Field
        key={ fieldId }
        name={ fieldName }
        placeholder={ placeholder || fieldName }
      >
        { ({ field }) => {
          const filteredOptions =
            layoutType === SCREENER_FORM
              ? fieldOptions?.filter(({ value }) => !isTextOrHiddenType(value))
              : fieldOptions;
          const formStateQuestionSetField = getQuestionSetByFieldName(
            formState,
            fieldName
          );
          const valuesQuestionSetField = getQuestionSetByFieldName(
            questionSet,
            fieldName
          );
          const answerOptions =
            getFieldValueByFieldName(
              formStateQuestionSetField,
              QuestionSetTypes.ANSWER_OPTIONS
            ) ||
            getFieldValueByFieldName(
              valuesQuestionSetField,
              QuestionSetTypes.ANSWER_OPTIONS
            );

          const options = answerOptions
            ?.map(({ value }) => value)
            .filter(Boolean);

          const isConditionalToggled = getFieldValueByFieldName(
            formStateQuestionSetField,
            QuestionSetTypes.CONDITIONAL_TOGGLE
          );

          return (
            <RadioSet
              hasErrors={ !!hasErrors }
              errorMessage={ errorMessage }
              disabled={ disabled }
              name={ fieldName }
              id={ fieldName }
              multiple={ false }
              label={
                <label>
                  { getFieldLabel(fieldLabel || field.name) }
                  <span className={ `asterisk ${ hasErrors ? 'hasErrors' : '' }` }>
                    *
                  </span>
                </label>
              }
              selectedValue={ field.value || fieldValue }
              options={ filteredOptions }
              onChange={ onChange }
              answerOptions={ options }
              isConditionalToggled={ isConditionalToggled }
            />
          );
        } }
      </Field>
    </FieldWrapper>
  );
};

InquiryRadioField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldOptions: PropTypes.arrayOf(selectOptionPropType),
  fieldValue: PropTypes.string,
  formState: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  hasErrors: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  questionSet: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
};
