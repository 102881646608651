// modules
import React from 'react';
import { Container } from '@material-ui/core';
import { Switch, Route } from 'react-router-dom';

// aliased
import NotFound from 'components/NotFound';

// local
import List from './List';
import Token from './Token';
import AccessControl from './AccessControl';
import EditConfig from './ConfigEdit/Edit';
import ConfigEdit from './ConfigEdit';
import { EventJobs } from './EventJobs';
import ChangeEnv from './Env';
import { EventJobViewPage } from './EventJobs/ViewDetails';
//TODO
//ADD withRolePermissions(DevView)

const Initiative = () => {
  return (
    <Container>
      <Switch>
        <Route path="/dev" exact={ true } component={ List } />
        <Route path="/dev/token" exact={ true } component={ Token } />
        <Route path="/dev/access" exact={ true } component={ AccessControl } />
        <Route path="/dev/jobs" exact={ true } component={ EventJobs } />
        <Route path="/dev/config" exact={ true } component={ ConfigEdit } />
        { /* TODO only allow dev/env from staging,not prod */ }
        <Route path="/dev/env" exact={ true } component={ ChangeEnv } />
        <Route
          path="/dev/jobs/:jobId"
          exact={ true }
          component={ EventJobViewPage }
        />
        <Route path="/dev/config/edit/:configHandle" component={ EditConfig } />
        <Route component={ NotFound } />
      </Switch>
    </Container>
  );
};

export default Initiative;
