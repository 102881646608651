import React from 'react';
import PropTypes from 'prop-types';
import FieldWrapper from 'components/FieldWrapper';
import {
  QuestionSetTypes,
  getFieldValueByFieldName,
  getQuestionSetByFieldName,
} from '../../RepeatableFieldsetUtils';
import { isTextOrHiddenType } from 'components/forms/utils';
import { SCREENER_FORM, SURVEY_FORM } from 'lib/layoutUtils';
import { getFieldLabel } from '../../lib';
import { TagsInput } from 'components/fields';
import { AnswerOptions } from 'components/fields/AnswerOptionField';
import { Text } from '@nike/eds';
import { Field } from 'formik';
import {
  arrayOfObjects,
  initialValuesTypes,
  objectOrArray,
} from 'lib/propTypes';
import styles from '../../index.module.scss';

const renderOptionHeaders = ({
  layoutType,
  isCorePPI,
  isApproved,
  existingPPI,
}) => (
  <div className={ styles.answerOptions }>
    { isCorePPI && (
      <Text className="eds-type--title-6 eds-spacing--mb-4">
        Answer options
      </Text>
    ) }
    <div
      className={
        isCorePPI
          ? styles.toggleNonCore
          : layoutType === SCREENER_FORM && !isApproved
            ? styles.toggleScreenerHeader
            : styles.toggleSurveyHeader
      }
    >
      { isApproved && <span className="eds-type--subtitle-2">Accept</span> }
      { !existingPPI && <span className="eds-type--subtitle-2">Delete</span> }
    </div>
  </div>
);

/* eslint-disable complexity */
export const TagsInputField = ({
  fieldId,
  fieldLabel,
  fieldName,
  fieldValue,
  placeholder,
  disabled,
  hasErrors,
  errorMessage,
  formState,
  questionSet,
  errors,
  layoutType,
  onChange,
  isCorePPI,
  existingPPI,
  isApproved,
}) => {
  return (
    <FieldWrapper disabled={ disabled }>
      <Field
        key={ fieldId }
        name={ fieldName }
        placeholder={ placeholder || fieldName }
      >
        { ({ field }) => {
          let isTextOrHiddenAnsType;
          const headersFields = {
            layoutType,
            isCorePPI,
            isApproved,
            existingPPI,
          };
          const formStateQuestionSetField = getQuestionSetByFieldName(
            formState,
            fieldName
          );
          const valuesQuestionSetField = getQuestionSetByFieldName(
            questionSet,
            fieldName
          );
          const ppiHandleSelected =
            getFieldValueByFieldName(
              formStateQuestionSetField,
              QuestionSetTypes.EXISTING_PPI
            )?.value ||
            getFieldValueByFieldName(
              valuesQuestionSetField,
              QuestionSetTypes.EXISTING_PPI
            )?.value;

          if (layoutType === SURVEY_FORM) {
            const questionType =
              getFieldValueByFieldName(
                formStateQuestionSetField,
                QuestionSetTypes.ANSWER_TYPE
              ) ||
              getFieldValueByFieldName(
                valuesQuestionSetField,
                QuestionSetTypes.ANSWER_TYPE
              );
            isTextOrHiddenAnsType = isTextOrHiddenType(questionType);
          }

          return layoutType === SCREENER_FORM ||
            (layoutType === SURVEY_FORM && !isTextOrHiddenAnsType) ? (
              <>
                { renderOptionHeaders(headersFields) }
                <AnswerOptions
                  hasErrors={ hasErrors }
                  errorMessage={ errorMessage }
                  name={ fieldName }
                  id={ fieldName }
                  field={ field }
                  title={ getFieldLabel(fieldLabel || field.name) }
                  tags={ fieldValue || field.value }
                  onChange={ onChange }
                  existingPPI={ existingPPI }
                  isApproved={ isApproved }
                  disabled={ disabled }
                  formState={ formState }
                  errors={ errors }
                  layoutType={ layoutType }
                  ppiHandle={ ppiHandleSelected }
                />
              </>
            ) : (
              !isTextOrHiddenAnsType && (
                <TagsInput
                  hasErrors={ hasErrors }
                  errorMessage={ errorMessage }
                  disabled={ disabled }
                  name={ fieldName }
                  id={ fieldName }
                  fullWidth={ true }
                  variant="outlined"
                  title={ getFieldLabel(fieldLabel || field.name) }
                  helperText="Type or paste options separated by a tab or line break. Min 2."
                  tags={ fieldValue || field.value }
                  onChange={ onChange }
                  separators={ ['Enter', 'Tab'] }
                  required={ true }
                />
              )
            );
        } }
      </Field>
    </FieldWrapper>
  );
};

TagsInputField.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  existingPPI: PropTypes.string,
  fieldId: PropTypes.string,
  fieldLabel: PropTypes.string,
  fieldName: PropTypes.string,
  fieldValue: arrayOfObjects,
  formState: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
  hasErrors: PropTypes.bool,
  isApproved: PropTypes.bool,
  isCorePPI: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  questionSet: PropTypes.oneOfType([
    PropTypes.oneOf([undefined]),
    initialValuesTypes.initialInquiryData,
  ]),
};
