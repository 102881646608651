/*eslint-disable max-statements */
import React, { useCallback, useEffect, useState } from 'react';
import { InitiativeParticipationStatuses, ViewMode } from 'lib/enums';
import PropTypes from 'prop-types';
import { useToasts } from 'react-toast-notifications';
import { concat, last, omit, propOr } from 'ramda';

// aliased
import { AssetForm } from 'components/forms';
import { convertHandleToTitle } from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import { useCompileLayoutMutation } from 'features/adminApi/endpoints/cms';
import {
  STATE_BASED_CONTENT,
  DEFAULT_MEDIA,
  updateLayout,
  generateDefaultCards,
  getAssetType,
  constructLayoutHandle,
  SURVEY_FORM,
  retrieveShortHandle,
  CARD_CONSTANTS,
  INQUIRY_CONSTANTS_V2,
} from 'lib/layoutUtils';
import { isEmpty, isEqual, kebabCase, startCase } from 'lodash';
import { useHistory, useParams, useLocation } from 'react-router-dom';
import { Button, Tab, TabGroup, Text } from '@nike/eds';
import { renderCtasBasedOnParticipation } from '../utils';
import PreviewInANewWinBtn from './PreviewNewWinBtn';
import { saveInitNavData } from 'utils';
import { initialValuesTypes } from 'lib/propTypes';
import { PublishModalStyled } from './AssetCard/styled';

// eslint-disable-next-line complexity
const AddEditForm = ({
  activeId,
  initialData,
  assetType,
  edit,
  layoutType,
  isLoading,
  preview,
  setPreview,
  formFields,
  handleTabChange,
  data,
  usePatch,
  useCreate,
  patchResult,
  submitText,
  postResult,
  updateFields,
  layoutId,
  initiativeHandle,
  isApproved,
  handleIsApproved,
  viewMode: layoutOption,
}) => {
  const { addToast } = useToasts();
  const location = useLocation();
  const draftExist = location?.state?.draftExist;
  const [currentData, setCurrentData] = useState({});
  const [useCompileLayout] = useCompileLayoutMutation();
  const [layoutIds, setlayoutIds] = useState([]);
  const [formFieldsWithState, setFormFieldsWithState] = useState([]);
  const [formState, setFormState] = useState();
  const [activelayoutId, setActivelayoutId] = useState(activeId);
  const history = useHistory();
  const { initiativeType, viewMode, id } = useParams();
  const shortHandle = retrieveShortHandle(id);
  const [isTabDisabled, setIsTabDisabled] = useState(false);

  useEffect(() => {
    setIsTabDisabled(isEqual(formState, initialData));
    setActivelayoutId(activeId);
  }, [activeId, formState, initialData]);

  useEffect(() => {
    if (initialData) setFormState(initialData);
  }, [initialData]);

  useEffect(() => {
    if (preview) {
      const { show: _, ...previewWithoutShow } = preview;
      setFormState((prevState) => ({ ...prevState, ...previewWithoutShow }));
    }
  }, [preview]);

  const handleChange = useCallback((event) => {
    handleTabChange(event);
  }, []);

  useEffect(() => {
    const assetData = !isEmpty(data)
      ? data
        ?.filter((card) => {
          return layoutType === 'defaultMedia'
            ? card?.contentType === 'default-media'
            : card?.id === layoutId;
        })
        .shift()
      : [];
    setCurrentData(assetData);
  }, [layoutId, data]);

  const siblingIds = history?.location?.state?.siblingIds
    ? history?.location?.state?.siblingIds
    : [];

  const ordering = {},
        sortOrder = InitiativeParticipationStatuses;
  for (let i = 0; i < sortOrder.length; i++) {
    ordering[sortOrder[i]] = i;
  }

  const sorted =
    siblingIds &&
    siblingIds.sort((first, last) => {
      return ordering[first?.tabName] - ordering[last?.tabName];
    });

  const tabSet = sorted;

  const handleSubmit = useCallback(
    async (values, { setSubmitting }) => {
      setSubmitting(true);
      const returnLayoutData = [];
      const valuesToUpdate = {
        ...currentData,
        ...values,
        questionSet: values?.questionSet || formFields,
      };

      const refinedValuesToUpdate = {
        ...valuesToUpdate,
        attributes: {
          isAutomated: isApproved,
        },
        text: valuesToUpdate?.text
          ?.replace(/\n\\\n/g, '\n\n')
          .replace(/\\/g, ''),
      };
      const initiativeParticipationStatus = lookupTabState(activelayoutId);

      const {
        call,
        layoutType: updatedLayout,
        values: contentValues,
      } = updateFields(
        id,
        refinedValuesToUpdate,
        assetType,
        [], //layoutIds,
        draftExist,
        assetType === SURVEY_FORM ? data : initiativeParticipationStatus,
        shortHandle,
        viewMode
      );

      if (call === 'patch') {
        const modifyContentValues = { ...contentValues };
        await usePatch(modifyContentValues);
        if (contentValues.contentType === 'default-media') {
          saveInitNavData(contentValues, false);
        }
        setSubmitting(false);
      } else if (call === 'post') {
        const { data: contentData } = await useCreate(contentValues);
        returnLayoutData.push({
          contentType: updatedLayout,
          layoutId: contentData?.id,
        });
        setSubmitting(false);
      }

      //For sibling content (initPartStatus)- if call=post + status=preview,
      //add previews for the siblings
      if (tabSet && call === 'post') {
        tabSet
          .filter((item) => item.layoutId !== valuesToUpdate.id)
          .map((layout) => {
            if (
              parseFloat(layout.layoutId, 10) !==
              parseFloat(valuesToUpdate.id, 10)
            ) {
              updateLayout('post', layout.layoutId, useCreate, data);
            }
          });
      }

      if (updatedLayout === DEFAULT_MEDIA) {
        const layoutHandle = constructLayoutHandle(shortHandle, updatedLayout);
        await useCompileLayout(layoutHandle);
        const contentCards = generateDefaultCards(
          id,
          data,
          values,
          shortHandle
        );

        const contentCardsUpdated = [];

        contentCards?.map(async (content, idx) => {
          const stateBased = STATE_BASED_CONTENT.includes(content.layoutType);
          if (stateBased) {
            const initStatus =
              content.transformedValues.initiativeParticipationStatus;
            const layoutType =
              initStatus &&
              concat(
                propOr('', 'layoutType', content),
                concat('-', initStatus.toString().toLowerCase())
              );

            contentCardsUpdated.push({
              call: content.call,
              layoutType,
              transformedValues: {
                ...omit(['contentType'], content.transformedValues),
                contentType: propOr('', 'layoutType', content),
                initiativeParticipationStatus: initStatus,
              },
            });
          } else {
            contentCardsUpdated.push(content);
          }
        });

        const promises = contentCardsUpdated?.map(async (content) => {
          if (content?.call) {
            const layoutHandle = constructLayoutHandle(
              shortHandle,
              content?.layoutType
            );
            if (content?.call === 'patch') {
              //Enable below if want to compile after a default media update
              const patchContentResult = await usePatch(
                content.transformedValues
              );
              await useCompileLayout(layoutHandle);
              return {
                content,
                contentType: content.layoutType,
                layoutId: patchContentResult?.data?.id,
              };
            } else if (content?.call === 'post') {
              const postData = { ...content?.transformedValues };
              const postContentResult = await useCreate(postData);
              //Enable below if want to compile after a default media update
              await useCompileLayout(layoutHandle);
              return {
                content,
                contentType: content.layoutType,
                layoutId: postContentResult?.data?.id,
              };
            }
          }
        });

        const results = await Promise.all(promises);
        const returnLayoutData = results.filter(Boolean);

        setlayoutIds([...layoutIds, ...returnLayoutData]);
        return returnLayoutData;
      }
    },
    [isApproved, initialData, layoutIds] //, data]
  );

  const handleSave = useCallback(
    (previewInNewWindow, entityType) => {
      if (previewInNewWindow === 'preview-in-new-window') {
        const previewURL = `../${ ViewMode.PREVIEW }/${ entityType }`;
        const newWindow = window.open(
          `${ previewURL }`,
          '_blank',
          'height=1450,width=1450'
        );
        newWindow.previewData = { ...formState, show: true };
        newWindow.layoutType = entityType;
      }
      setPreview({ ...formState, show: true });
    },
    [formState]
  );

  useEffect(() => {
    const initEditPage = `/initiative/${ initiativeType }/edit/${ id }`;
    const initAssetsPage = `/initiative/${ initiativeType }/${ id }/assets`;
    const getPath = (isDefaultMedia = false) =>
      (isDefaultMedia
        ? (location.state?.prevUrl ?? '').endsWith('/assets')
          ? initAssetsPage
          : initEditPage
        : initAssetsPage);

    const getAssetPath = {
      create: getPath(),
      edit: getPath(),
    };

    const assetPath = {
      create: getAssetPath.create,
      edit: getAssetPath.edit,
    };

    const returnPath = {
      screener: assetPath,
      consentDocument: assetPath,
      mobileOverviewCard: assetPath,
      mobileReturningCard: assetPath,
      mobileDetailScreen: assetPath,
      webOverviewCard: assetPath,
      webDetailPage: assetPath,
      defaultMedia: {
        create: getPath(true),
        edit: getPath(true),
      },
      survey: assetPath,
    };

    if (patchResult?.error?.data?.errorMessage) {
      addToast(patchResult?.error?.data.errorMessage, {
        appearance: 'error',
        autoDismiss: true,
      });
    }

    if (patchResult?.isSuccess) {
      //Adding some minimal delay until the data is invalidated from inquires api.
      setTimeout(() => {
        addToast(`${ convertHandleToTitle(currentData?.handle) } Updated`, {
          appearance: 'success',
          autoDismiss: true,
        });

        const assetType = getAssetType(layoutType);

        history.push({
          pathname:
            returnPath?.[layoutType]?.[viewMode] ||
            `/initiative/${ initiativeType }/edit/${ id }`,
          state: {
            layoutId: layoutId || currentData?.id,
            disabled: true,
            draftExist,
            assetType,
          },
        });
      }, 250);
    }
    if (postResult?.isSuccess) {
      addToast('Content Update Complete', {
        appearance: 'success',
        autoDismiss: true,
      });
      history.push({
        pathname:
          returnPath[layoutType][viewMode] ||
          `/initiative/${ initiativeType }/${ currentData?.handle }/edit/${ id }`,
        state: {
          layoutId: layoutId || currentData?.id,
          disabled: true,
          draftExist,
          assetType: getAssetType(layoutType),
        },
      });
    }
  }, [
    patchResult,
    postResult,
    initiativeHandle,
    id,
    viewMode,
    layoutType,
    history,
  ]);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalInfo, setModalInfo] = useState({ heading: '', body: '' });

  const dismissModal = useCallback(() => {
    setModalOpen(!modalOpen);
  }, [modalOpen]);

  useEffect(() => {
    if (postResult?.error && layoutType === SURVEY_FORM) {
      const { status, data } = postResult.error;
      if (status === 409) {
        setModalInfo({
          heading: 'Survey Handle Conflict Error',
          body: 'Survey handle is already in use. Survey handles must be unique. Please create a different handle for your survey and try again',
        });
        setModalOpen(true);
      } else if (data && data.errorMessage) {
        setModalInfo({
          heading: 'Error',
          body: data.errorMessage,
        });
        setModalOpen(true);
      } else {
        setModalInfo({
          heading: 'Unexpected Error',
          body: 'An unexpected error occurred. Please try again.',
        });
        setModalOpen(true);
      }
    }
  }, [postResult?.error]);

  const title = `${ startCase(`${ viewMode || ViewMode.EDIT } ${ assetType }`) } ${
    activelayoutId ? `(${ lookupTabState(activelayoutId) } initiative)` : ''
  }`;

  const addNew = initialData?.id ? false : viewMode === ViewMode.CREATE;
  const inquiryLayoutType = INQUIRY_CONSTANTS_V2.includes(kebabCase(assetType));
  const showForm =
    (viewMode || layoutOption) === ViewMode.EDIT || addNew || inquiryLayoutType;

  function lookupTabState(activelayoutId) {
    const activeTab = tabSet?.filter(
      (itm) => parseInt(itm.layoutId, 10) === parseInt(activelayoutId, 10)
    );
    return propOr('', 'tabName', last(activeTab));
  }

  useEffect(() => {
    const participationStatus = lookupTabState(activelayoutId);
    const modifiedFormFields = renderCtasBasedOnParticipation({
      initiativeParticipationStatus: participationStatus,
      cardContent: formFields,
    });

    const updatedFields =
      Array.isArray(modifiedFormFields) &&
      modifiedFormFields.map((field) => {
        return {
          ...field,
          activelayoutId,
          message:
            activelayoutId &&
            `Displayed when initiative participation status is '${ participationStatus }'`,
        };
      });
    setFormFieldsWithState(updatedFields);
  }, [formFields]);

  return (
    <div data-testid={ 'add-edit-form' }>
      <PublishModalStyled
        isOpen={ modalOpen }
        onDismiss={ dismissModal }
        headerSlot={
          <Text font="title-3" as="h3">
            { modalInfo.heading }
          </Text>
        }
        footerSlot={
          <Button variant="primary" onClick={ dismissModal }>
            Close
          </Button>
        }
      >
        { modalInfo.body }
      </PublishModalStyled>
      { showForm && (
        <Text
          font={ 'title-1' }
          as={ 'h1' }
          style={{ textTransform: 'capitalize' }}
        >
          { title }
        </Text>
      ) }
      { activelayoutId && (
        <TabGroup
          style={ showForm ? { marginTop: 30 } : { marginBottom: 30 } }
          name="manage-assets-tabs"
          activeId={ activelayoutId }
          onChange={ handleChange }
        >
          { tabSet &&
            tabSet.map((tab) => {
              return (
                <Tab
                  key={ tab.layoutId }
                  id={ propOr('', 'layoutId', tab).toString() }
                  disabled={
                    parseInt(activelayoutId, 10) !==
                      parseInt(tab.layoutId, 10) && !isTabDisabled
                  }
                >
                  { tab.tabName }
                </Tab>
              );
            }) }
        </TabGroup>
      ) }
      { showForm ? (
        <>
          { isLoading ? (
            <Loading show={ edit } text="Fetching Content Data" />
          ) : (
            <AssetForm
              key={ activelayoutId }
              initiativeHandle={ initiativeHandle }
              submitText={ submitText }
              layoutType={ layoutType }
              isLoading={ isLoading }
              initialValues={ initialData }
              viewMode={ viewMode }
              fields={ formFieldsWithState }
              setFormState={ setFormState }
              formState={ formState }
              handleSave={ handleSave }
              handleSubmit={ handleSubmit }
              draftExist={ draftExist }
              cardStatus={ currentData?.status }
              isApproved={ isApproved }
              currentData={ currentData }
              handleIsApproved={ handleIsApproved }
            />
          ) }
        </>
      ) // Render below the button only on card layouts (except "default-media")
        : CARD_CONSTANTS.includes(kebabCase(layoutType)) ? (
          <PreviewInANewWinBtn
            handleSave={ handleSave }
            layoutType={ layoutType }
            variant={ 'secondary' }
          />
        ) : null }
    </div>
  );
};

AddEditForm.propTypes = {
  activeId: PropTypes.string,
  assetType: PropTypes.string,

  data: PropTypes.arrayOf(PropTypes.shape({})),

  edit: PropTypes.bool,

  formFields: PropTypes.arrayOf(PropTypes.shape({})),

  handleIsApproved: PropTypes.func,
  handleTabChange: PropTypes.func,

  id: PropTypes.string,
  initialData: PropTypes.oneOfType([
    initialValuesTypes.initialInquiryData,
    initialValuesTypes.initialConsentData,
    initialValuesTypes.initialContentData,
  ]),
  initiativeHandle: PropTypes.string,
  isApproved: PropTypes.bool,
  isLoading: PropTypes.bool,

  layoutId: PropTypes.number,
  layoutType: PropTypes.string,

  patchResult: PropTypes.shape({
    isSuccess: PropTypes.bool,
    error: PropTypes.shape({
      data: PropTypes.shape({ errorMessage: PropTypes.string }),
    }),
  }),
  postResult: PropTypes.shape({
    isSuccess: PropTypes.bool,
    error: PropTypes.shape({
      status: PropTypes.number,
      data: PropTypes.shape({ errorMessage: PropTypes.string }),
    }),
  }),
  preview: PropTypes.shape({
    show: PropTypes.bool,
  }),
  setPreview: PropTypes.func,
  submitText: PropTypes.string,
  updateFields: PropTypes.func,
  useCreate: PropTypes.func,
  usePatch: PropTypes.func,
  viewMode: PropTypes.string,
};

export default AddEditForm;
