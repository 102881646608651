import { isArray } from 'lodash';

export const CategoryFields = {
  CATEGORY_NAME: 'categoryName',
  CATEGORY_TITLE: 'categoryTitle',
  CATEGORY_SUBTITLE: 'categorySubtitle',
  CATEGORY_TEXT: 'categoryText',
  BACKGROUND_IMAGE: 'backgroundImage',
  CATEGORY_CHOICE: 'categoryChoice',
};

export const QuestionSetTypes = {
  CONDITIONAL_TOGGLE: 'conditionalToggle',
  CONDITIONAL_OPTIONS: 'conditionalOptions',
  QUESTION_LABEL: 'questionLabel',
  QUESTION_CATEGORY: 'questionCategory',
  EXISTING_PPI: 'existingPPI',
  ANSWER_TYPE: 'answerType',
  ANSWER_OPTIONS: 'answerOptions',
  ...CategoryFields,
};

export const isCategoryField = (fieldName) => {
  const categoryFieldValues = Object.values(CategoryFields);
  return categoryFieldValues.includes(fieldName);
};

export const getFieldValueByFieldName = (questionSet, fieldName) => {
  if (isArray(questionSet)) {
    const field = questionSet?.find(
      ({ field = '' }) => typeof field === 'string' && field.includes(fieldName)
    );
    return field?.value;
  }
};

export const getFieldValuesByFieldNames = (questionSet, fieldNames) => {
  if (isArray(questionSet) && isArray(fieldNames)) {
    const matchingFields = questionSet.reduce((acc, item) => {
      const fieldNameMatches = fieldNames.find(
        (fieldName) =>
          typeof item.field === 'string' && item.field.includes(fieldName)
      );

      if (fieldNameMatches) {
        acc[fieldNameMatches] = item.value;
      }
      return acc;
    }, {});

    return matchingFields;
  }
  return {};
};

export const getFieldByFieldName = (questionSet, fieldName) => {
  if (isArray(questionSet)) {
    const field = questionSet?.find(
      ({ field = '' }) => typeof field === 'string' && field.includes(fieldName)
    );
    return field;
  }
};

export const getLastNonConditionalIdx = (index, questionSets) => {
  let nonConditionalIndex = index - 1;
  while (
    nonConditionalIndex >= 0 &&
    getFieldValueByFieldName(
      questionSets?.[nonConditionalIndex]?.value,
      QuestionSetTypes.CONDITIONAL_TOGGLE
    )
  ) {
    nonConditionalIndex--;
  }
  return nonConditionalIndex;
};

export const setDynamicQuestionLabels = (questionSets) => {
  let conditionalCount = 0;
  let nonConditionalCount = 0;

  return questionSets.map((question) => {
    if (
      getFieldValueByFieldName(
        question?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      conditionalCount++;
      return {
        ...question,
        dynamicQuestionLabel: `Conditional Question ${ conditionalCount }`,
      };
    }
    nonConditionalCount++;
    conditionalCount = 0;

    const categoryName = getFieldValueByFieldName(
      question?.value,
      QuestionSetTypes.QUESTION_CATEGORY
    )?.value;

    return {
      ...question,
      dynamicQuestionLabel: categoryName ?? `Item ${ nonConditionalCount }`,
    };
  });
};

/* eslint-disable  max-statements */
export const setUsedInConditionalLabels = (questionSets) => {
  const updatedQuestionsSets = [...questionSets];

  updatedQuestionsSets.forEach((question, index) => {
    if (
      getFieldValueByFieldName(
        question?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      const nonConditionalIndex = getLastNonConditionalIdx(
        index,
        updatedQuestionsSets
      );
      if (nonConditionalIndex >= 0) {
        const prevQuestionSet = updatedQuestionsSets[nonConditionalIndex];
        prevQuestionSet.isUsedInConditional = true;
        question.conditionalPPI = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.EXISTING_PPI
        )?.value;
        question.conditionalLabel = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.QUESTION_LABEL
        );
        question.conditionalQuestionType = getFieldValueByFieldName(
          prevQuestionSet.value,
          QuestionSetTypes.ANSWER_TYPE
        );
      }
    } else if ('isUsedInConditional' in question) {
      delete question?.isUsedInConditional;
    }
  });

  return updatedQuestionsSets;
};

export const getQuestionSetByFieldName = (questionSets, fieldName) => {
  if (isArray(questionSets)) {
    const filteredQuestionSet = questionSets?.find(
      (questionSet) => questionSet?.fieldSetId === +fieldName?.split('-')[1]
    )?.value;
    return filteredQuestionSet;
  }
};

export const getQuestionSetIndex = (questionSets, fieldName) => {
  if (isArray(questionSets)) {
    return questionSets?.findIndex(
      (questionSet) => questionSet?.fieldSetId === +fieldName.split('-')[1]
    );
  }
};

export const findMatchingQuestionSetsWithIndex = (
  questionSets,
  categoryValue,
  currentQuestionSetIdx
) => {
  if (isArray(questionSets) || questionSets.length > 0) {
    return questionSets
      .map((questionSet, index) => ({ questionSet, index }))
      .filter(
        ({ questionSet }, index) =>
          questionSet.value[0].value.value === categoryValue &&
          index !== currentQuestionSetIdx
      );
  }
  return [];
};

export const setConditionalToggledLabel = (initialValues) => {
  const updatedQuestionSets = initialValues?.map((questionSet) => {
    if (
      getFieldValueByFieldName(
        questionSet?.value,
        QuestionSetTypes.CONDITIONAL_TOGGLE
      )
    ) {
      return {
        ...questionSet,
        hasConditionalToggled: true,
      };
    }
    return questionSet;
  });
  return updatedQuestionSets;
};
