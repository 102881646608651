// modules
import React from 'react';
import { Container, Typography, Button } from '@material-ui/core';

// aliased

// local
import styles from './DevView.module.scss';
import { FlexCenter } from 'components/FlexCenter';
import Link from 'components/Link';

const DevView = () => {
  return (
    <Container className={ styles.wrapper }>
      <FlexCenter style={{ height: '100%' }}>
        <div className={ styles.content }>
          <Typography variant="h3" align="center">
            JTL Admin Dev Tools
          </Typography>
          <Typography variant="body1" align="center">
            Quick Links:
          </Typography>
          <Button component={ Link } variant="outlined" to="/dev/token">
            Global ID Tools
          </Button>
          <Button component={ Link } variant="outlined" to="/dev/access">
            Access Control Roles
          </Button>
          <Button component={ Link } variant="outlined" to="/dev/config">
            Config Management
          </Button>
          <Button component={ Link } variant="outlined" to="/dev/jobs">
            Event jobs
          </Button>

          <Button component={ Link } variant="outlined" to="/dev/env">
            Change API Endpoint
          </Button>
        </div>
      </FlexCenter>
    </Container>
  );
};

export default DevView;
