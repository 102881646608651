import React, { useEffect, useMemo, useState } from 'react';
import { ppiAnswerOptions } from 'components/forms/Assets/lib';
import { useField } from 'formik';
import { errorMessage } from './utils';
import { ToggleButton } from './Components/ToggleButton';
import { DeleteButton } from './Components/IconButton';
import { OptionInputField } from './Components/OptionInputField';
import { AddAnswerOption } from './Components/AddAnswerOption';
import { OptionDescriptionField } from './Components/OptionDescription';
import { SURVEY_FORM } from 'lib/layoutUtils';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { objectOrArray } from 'lib/propTypes';
import {
  QuestionSetTypes,
  getFieldByFieldName,
  getFieldValueByFieldName,
} from 'components/forms/Assets/RepeatableFieldsetUtils';
import { isArray } from 'lodash';

// eslint-disable-next-line max-statements
export const AnswerOptions = (props) => {
  const [answerOption, setAnswerOption] = useState([]);
  const [isAnswerOptionsUpdated, setIsAnswerOptionsUpdated] = useState(false);
  const [touched, setTouched] = useState(false);
  const [{ ...field }] = useField(props);

  const {
    onChange,
    existingPPI,
    tags,
    isApproved,
    disabled,
    formState,
    errors,
    layoutType,
    ppiHandle,
  } = props;

  const questionId = Number(field?.name?.split('-')[1]);
  const assocPPI = isArray(formState)
    ? formState.filter((itm) => itm?.fieldSetId === questionId)
    : [];
  const isSelectedPPI = useMemo(() => {
    return (
      getFieldValueByFieldName(
        assocPPI[0]?.value,
        QuestionSetTypes.EXISTING_PPI
      )?.value ===
      getFieldByFieldName(assocPPI[0]?.value, QuestionSetTypes.ANSWER_OPTIONS)
        ?.ppiHandle
    );
  }, [formState, field]);

  const createAnswerOptions = () => {
    const corePpiOptions = ppiAnswerOptions(existingPPI);
    const checkTagsContainCorePpi =
      tags ??
      tags?.some(({ value }, index) => value === corePpiOptions[index]?.value);

    const updatedOptions = checkTagsContainCorePpi
      ? corePpiOptions?.map((corePPI) => ({
        ...corePPI,
        ...tags.find((tagPPI) => tagPPI.value === corePPI.value),
      }))
      : corePpiOptions;

    const updatedTags = existingPPI
      ? updatedOptions
      : isSelectedPPI
        ? tags
        : [];

    return updatedTags
      ? updatedTags.map(({ label, name, value, selected, attributes }) => ({
        id: Math.random(),
        label: name || label,
        value,
        isReject:
            attributes?.isReject !== undefined
              ? attributes.isReject
              : selected !== undefined
                ? !selected
                : isApproved,
        ...(layoutType === SURVEY_FORM &&
            !existingPPI && {
          description: attributes?.description || '',
        }),
      }))
      : [];
  };

  useEffect(() => {
    const options = createAnswerOptions();
    setAnswerOption(options);
    setIsAnswerOptionsUpdated(true);
  }, [existingPPI, isSelectedPPI]);

  useEffect(() => {
    if (isAnswerOptionsUpdated && !isSelectedPPI) {
      onChange({ target: { name: field.name, ppiHandle } }, answerOption);
    }
  }, [answerOption, isAnswerOptionsUpdated, isSelectedPPI]);

  const answerOptionError =
    errors &&
    Object.keys(errors).some((key) =>
      key.includes(QuestionSetTypes.ANSWER_OPTIONS)
    ) &&
    errors[
      Object.keys(errors).find((key) =>
        key.includes(QuestionSetTypes.ANSWER_OPTIONS)
      )
    ];

  const commonProps = {
    fieldName: field.name,
    disabled,
    onChange,
    answerOption,
    setAnswerOption,
    ppiHandle,
  };

  return (
    <div>
      <span className={ styles.errorMessage }>
        { answerOption.length < 2 && touched && errorMessage }
      </span>
      <div />
      { answerOption &&
        answerOption?.map(({ id, label, value, description, isReject }) => {
          const componentProps = {
            ...commonProps,
            id,
          };

          return (
            <div key={ id }>
              <div
                style={{ width: layoutType === SURVEY_FORM ? '98%' : '100%' }}
                className={ styles.answerOptionWrap }
              >
                <div className={ styles.answerFieldsWrapper }>
                  <OptionInputField
                    { ...componentProps }
                    label={ label }
                    value={ value }
                    existingPPI={ existingPPI }
                    answerOptionError={ answerOptionError }
                  />
                  { layoutType === SURVEY_FORM && !existingPPI && (
                    <OptionDescriptionField
                      { ...componentProps }
                      label={ description }
                      value={ description }
                      existingPPI={ existingPPI }
                      answerOptionError={ answerOptionError }
                    />
                  ) }
                </div>
                { isApproved && (
                  <ToggleButton { ...componentProps } isReject={ isReject } />
                ) }
                { !existingPPI && (
                  <DeleteButton { ...componentProps } setTouched={ setTouched } />
                ) }
              </div>
            </div>
          );
        }) }
      <AddAnswerOption
        { ...commonProps }
        isApproved={ isApproved }
        existingPPI={ existingPPI }
        setTouched={ setTouched }
      />
    </div>
  );
};

AnswerOptions.propTypes = {
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  errors: objectOrArray,
  existingPPI: PropTypes.string,
  formState: PropTypes.arrayOf(Object),
  isApproved: PropTypes.bool,
  layoutType: PropTypes.string,
  onChange: PropTypes.func,
  ppiHandle: PropTypes.string,
  tags: PropTypes.arrayOf(Object),
};
