import React, { useEffect, useState } from 'react';
import { convertHandleToCardType } from 'components/forms/Assets/lib';
import Loading from 'components/Loading';
import {
  useCreateContentMutation,
  useFetchContentByIdQuery,
  useFetchContentQuery,
  usePatchContentMutation,
} from 'features/adminApi/endpoints/content';
import {
  checkAndUpdateMediaFields,
  DEFAULT_MEDIA,
  SCREENER_FORM,
} from 'lib/layoutUtils';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router-dom';
import { AddEditForm, Preview } from '../components';
import {
  fetchDefaultMedia,
  fetchDetailsPageData,
  filterContentBy,
} from '../utils';
import { isEmpty, kebabCase } from 'lodash';
import { ViewMode } from 'lib/enums';

// eslint-disable-next-line
const AddEditContents = ({
  edit,
  // This viewMode is a prop from src/views/Initiative/Experiences/components/Edit/index.js
  viewMode: layoutOption,
  handleTabChange,
  activeId,
}) => {
  //This viewMode is a route param from src/views/Initiative/Experiences/components/ManageAssets/index.js
  const { id, type, viewMode } = useParams();
  const history = useHistory();
  const [usePatchContent, patchContentResult] = usePatchContentMutation();
  const [useCreateContent, postContentResult] = useCreateContentMutation();

  const isPreviewMode = ViewMode.PREVIEW && window?.previewData;
  const assetType = type || DEFAULT_MEDIA;
  const layoutType = convertHandleToCardType(assetType);
  const showPreviewBlock = ![DEFAULT_MEDIA, SCREENER_FORM].includes(
    kebabCase(layoutType)
  );

  const layoutId = activeId
    ? activeId
    : parseInt(
      history?.location?.state?.layoutId ||
          //On exp-init getting item from session-storage causes item with layoutId to be called
          //For default media we don't need this data on init as it is present on init itself
          (assetType !== DEFAULT_MEDIA &&
            JSON.parse(sessionStorage.getItem('layoutId'))),
      10
    );

  const dataObject = useFetchContentQuery(id);

  //Grouping web and mobile detail pages
  let onWebDetailPage = false;
  let onMobileDetailPage = false;
  let webDetailPage = [];
  let mobileDetailPage = [];

  if (!dataObject.isLoading && dataObject.data) {
    ({ webDetailPage = [], mobileDetailPage = [] } = dataObject.data.reduce(
      (result, item) => {
        if (item.contentType === 'web-detail-page') {
          result.webDetailPage.push(item);
        } else if (item.contentType === 'mobile-detail-screen') {
          result.mobileDetailPage.push(item);
        }

        if (item.id === +layoutId) {
          if (item.contentType === 'web-detail-page') {
            onWebDetailPage = true;
          } else if (item.contentType === 'mobile-detail-screen') {
            onMobileDetailPage = true;
          }
        }

        return result;
      },
      { webDetailPage, mobileDetailPage }
    ));
  }

  const [contentData, setContentData] = useState([]);
  const [detailsPageData, setDetailsPageData] = useState([]);
  const [detailsPageLoading, setDetailsPageLoading] = useState(false);
  const [defaulMediaData, setDefaulMediaData] = useState(null);

  const { data: singleAssetData, isLoading: isAssetContentLoading } =
    useFetchContentByIdQuery(
      {
        initiativeHandle: id,
        id: layoutId,
      },
      {
        skip: !layoutId || !id || onMobileDetailPage || onWebDetailPage,
      }
    );

  useEffect(() => {
    if (assetType === DEFAULT_MEDIA) {
      const fetchData = async (itemId) => {
        const response = await fetchDefaultMedia(itemId, id);
        setDefaulMediaData(response);
        return response;
      };

      if (!dataObject.isLoading && !activeId) {
        const defaulMedia = dataObject.data?.find(
          (item) => item.contentType === DEFAULT_MEDIA
        );
        fetchData(defaulMedia?.id);
      }
    }
  }, [dataObject]);

  useEffect(() => {
    if (onWebDetailPage || onMobileDetailPage) {
      const fetchData = async () => {
        setDetailsPageLoading(true);
        const allData = await fetchDetailsPageData(
          webDetailPage,
          mobileDetailPage,
          id,
          onWebDetailPage
        );

        setDetailsPageData(allData);
        setDetailsPageLoading(false);
      };

      fetchData();
    }
  }, [onWebDetailPage, onMobileDetailPage]);

  useEffect(() => {
    if (!isAssetContentLoading || !detailsPageLoading) {
      const filterKey = assetType === DEFAULT_MEDIA ? 'contentType' : 'id';
      const contents = dataObject?.data;
      const filterValue =
        assetType === DEFAULT_MEDIA
          ? 'default-media'
          : parseInt(activeId, 10)
            ? parseInt(activeId, 10)
            : layoutId;

      const updateFetchedData = contents?.map((item) => {
        return assetType === DEFAULT_MEDIA && item.contentType === DEFAULT_MEDIA
          ? { ...item, ...(defaulMediaData || {}) }
          : item.id === +layoutId
            ? { ...item, ...(singleAssetData || {}) }
            : item;
      });

      //Reduces detailsPage array into a single object with id and item as value
      const detailsPageDataMap = detailsPageData?.reduce((map, item) => {
        map[item.id] = item;
        return map;
      }, {});

      // If the item exists in detailsPage object then use that otherwise use from content api
      const updatedData = updateFetchedData?.map((item) => {
        const matchingItem = detailsPageDataMap[item.id];
        return matchingItem ? matchingItem : item;
      });

      const modifiedDataObject = {
        ...dataObject,
        data: updatedData,
      };

      const dataObjectNew = dataObject
        ? filterContentBy(
          modifiedDataObject,
          filterKey,
          filterValue,
          layoutType
        )
        : [];
      setContentData({ ...dataObjectNew });
    }
  }, [activeId, dataObject, singleAssetData, detailsPageData, defaulMediaData]);

  const [preview, setPreview] = useState({ ...dataObject.currentData });

  return isPreviewMode ? (
    <Preview
      show={ window?.previewData?.show }
      cardType={ convertHandleToCardType(window?.layoutType) }
      content={{ ...window?.previewData }}
    />
  ) : isEmpty(contentData.formFields) ||
    isAssetContentLoading ||
    detailsPageLoading ? (
      <Loading text="Fetching Content Data" />
    ) : (
      <>
        <AddEditForm
          usePatch={ usePatchContent }
          useCreate={ useCreateContent }
          postResult={ postContentResult }
          patchResult={ patchContentResult }
          viewMode={ layoutOption }
          data={ contentData.data }
          edit={ edit }
          isLoading={ contentData.isLoading }
          id={ id }
          preview={ preview }
          setPreview={ setPreview }
          initialData={{ ...contentData.initialData }}
          assetType={ assetType }
          layoutType={ layoutType }
          formFields={ contentData.formFields }
          history={ history }
          updateFields={ checkAndUpdateMediaFields }
          layoutId={ +layoutId }
          // initiativeHandle={ contentData.initialData?.initiativeHandle }
          initiativeHandle={ id }
          activeId={ activeId }
          handleTabChange={ handleTabChange }
        />
        { showPreviewBlock && preview && (
          <Preview
            show={ showPreviewBlock }
            cardType={ layoutType }
            content={
            // Avoid merging currentData with preview in view mode to prevent issues
            // with preview state updating when activeId changes.
              viewMode === ViewMode.VIEW
                ? { ...contentData.currentData }
                : { ...contentData.currentData, ...preview }
            }
          />
        ) }
      </>
    );
};

AddEditContents.propTypes = {
  activeId: PropTypes.string,
  children: PropTypes.node,
  edit: PropTypes.bool,
  handleTabChange: PropTypes.func,
  viewMode: PropTypes.string,
};

export default AddEditContents;
