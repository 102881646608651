/* eslint-disable max-statements */
import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Select } from '@nike/eds';
import { useField } from 'formik';
import FieldWrapper from 'components/FieldWrapper';
import { isEmpty, kebabCase } from 'lodash';
import { selectOptionPropType } from 'lib/propTypes';
import { requiredError } from 'components/forms/utils';

//eslint-disable-next-line complexity
const SelectField = ({
  disabled,
  label,
  multiple,
  className,
  onChange,
  hasErrors,
  errorMessage,
  options,
  value: parentSelection,
  isCreatable,
  isConditionalSelect,
  ...props
}) => {
  const [{ value, ...field }, meta, helpers] = useField(props);

  //Hide close button from default select fields
  const styles = {
    multiValue: (base, { data }) =>
      (data.isFixed ? { ...base, backgroundColor: '#E5E5E5 !important' } : base),
    multiValueRemove: (base, { data }) =>
      (data.isFixed ? { ...base, display: 'none' } : base),
    clearIndicator: (base, { selectProps: { name } }) => ({
      ...base,
      display: name === 'requiredPpis' ? 'none' : 'block',
    }),
    indicatorSeparator: (base, { selectProps: { name } }) => ({
      ...base,
      display: name === 'requiredPpis' ? 'none' : 'block',
    }),
  };

  const handleChange = useCallback(
    (response, option) => {
      const { setValue, setTouched } = helpers;
      setTouched(true);

      if (option?.removedValue?.isFixed) {
        return;
      }

      if (Array.isArray(value) && value.includes(option?.removedValue?.value)) {
        //Remove value
        setValue(value.filter((val) => val !== option?.removedValue?.value));
      } else if (multiple && option?.option?.value) {
        //Add value
        setValue(
          Array.isArray(value)
            ? [...value, option.option.value]
            : [option.option.value]
        );
      } else {
        setValue(response.value);
      }
    },
    [value, multiple, helpers]
  );

  const formatValue = (value) => {
    if (options.length) {
      return !value
        ? ''
        : Array.isArray(value)
          ? value.map((val) => options?.filter((opt) => opt.value === val).pop())
          : options.filter((opt) => opt.value === value);
    }
  };

  const modifiedValue =
    (isCreatable
      ? isEmpty(parentSelection)
        ? ''
        : parentSelection
      : formatValue(parentSelection?.value || value)) ||
    (Array.isArray(parentSelection) && parentSelection) ||
    'Select one';

  const defaultValue = formatValue(value) || '';

  return (
    <FieldWrapper>
      <Select
        inputProps={{
          'data-testid': `${ kebabCase(label) }-select`,
        }}
        { ...field }
        { ...props }
        styles={ styles }
        name={ props.name }
        id={ props.name }
        hasErrors={
          !!(meta.touched && hasErrors) || !!(meta.touched && meta.error)
        }
        errorMessage={ errorMessage || requiredError }
        label={
          <label>
            { label }
            { props.required &&
              !(
                !!(meta.touched && hasErrors) || !!(meta.touched && meta.error)
              ) && <span className="asterisk">*</span> }
          </label>
        }
        aria-label={ label }
        options={ options }
        isMulti={ multiple }
        defaultValue={ defaultValue }
        onChange={ onChange || handleChange }
        isDisabled={ disabled }
        isCreatable={ isCreatable }
        value={ modifiedValue }
        onBlur={ useCallback(() => helpers.setTouched(true), []) }
        subtitle={
          isConditionalSelect &&
          'This question will only appear when the below answers are selected'
        }
        placeholder={ isConditionalSelect ? 'Select answers' : value }
      />
    </FieldWrapper>
  );
};

SelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  errorMessage: PropTypes.string,
  hasErrors: PropTypes.bool,
  isConditionalSelect: PropTypes.bool,
  isCreatable: PropTypes.bool,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(selectOptionPropType),
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    selectOptionPropType,
    PropTypes.arrayOf(selectOptionPropType),
    PropTypes.shape({}),
  ]),
};

SelectField.defaultProps = {
  className: null,
  label: '',
  multiple: false,
  options: [],
};

export default memo(SelectField);
